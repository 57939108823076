import { whatsappVariables } from "constants/constants";
import React, { Component } from "react";

class VariableSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { setSelectedVariable } = this.props;
    return (
      <React.Fragment>
        <div className="mb-1">
          <select
            id="groupSelection"
            className="form-select width-10rem"
            onClick={event => setSelectedVariable(event.target.value)}
          >
            <option value="">Select a Variable</option>
            {Object.values(whatsappVariables).map(
              (whatsappVariablesName, index) => (
                <option
                  value={whatsappVariablesName}
                  key={whatsappVariablesName}
                >
                  {whatsappVariablesName}
                </option>
              )
            )}
          </select>
        </div>
      </React.Fragment>
    );
  }
}

export default VariableSelect;
// import { whatsappVariables } from "constants/constants";
// import React, { Component } from "react";

// class VariableSelect extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       selectedField: "",
//       customValue: "",
//       isCustom: false,
//     };
//   }

//   handleCustomValueChange = value => {
//     // this.setState(prevState => ({
//     //   customValues: {
//     //     ...prevState.customValues,
//     //     [key]: value,
//     //   },
//     // }));
//     this.setState(prevState => ({ ...prevState, customValue: value }));
//   };

//   render() {
//     const { setSelectedVariable } = this.props;
//     const { customValue } = this.state;

//     return (
//       <React.Fragment>
//         <div className="mb-1">
//           <select
//             id="groupSelection"
//             className="form-select width-10rem"
//             onChange={event => {
//               const selectedValue = event.target.value;
//               this.setState(prevState => ({
//                 ...prevState,
//                 selectedField: selectedValue,
//               }));
//               // setSelectedVariable(selectedValue);
//               if (selectedValue.startsWith("CUSTOM")) {
//                 this.setState(prevState => ({ ...prevState, isCustom: true }));
//                 // this.handleCustomValueChange(selectedValue, ""); // Reset custom value
//               }
//             }}
//           >
//             <option value="">Select a Variable</option>
//             {Object.values(whatsappVariables).map(whatsappVariablesName => (
//               <option value={whatsappVariablesName} key={whatsappVariablesName}>
//                 {whatsappVariablesName}
//               </option>
//             ))}
//           </select>
//         </div>

//         {this.state.isCustom && (
//           <div className="mb-1">
//             <input
//               type="text"
//               placeholder={`Enter value`}
//               value={customValue}
//               onChange={event => {
//                 setSelectedVariable(
//                   this.state.selectedField,
//                   event.target.value
//                 );
//                 // this.handleCustomValueChange(event.target.value);
//               }}
//               className="form-control"
//             />
//           </div>
//         )}
//       </React.Fragment>
//     );
//   }
// }

// export default VariableSelect;
