import React, { Component } from "react";
import { Col, Row, Modal, ModalHeader, ModalBody, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  AcceptedFileTypes,
  bytesToMegabytes,
  capitalizeFirstLetter,
  fileSizeAllowed,
  fileType,
  tag,
  templateComponentType,
  templateType,
  whatsappTemplateButtonTypes,
  whatsappTemplateParameterTypes,
  whatsappTemplates,
} from "constants/constants";
import { axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";
import { toast } from "react-toastify";
import ModalLoader from "components/Common/ModalLoader";
import VariableSelect from "../VariableSelect";

class Whatsapp extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      showModalLoader: false,
      uploadedFile: {},
      whatsappNumbers: [],
      selectedNumber: "",
      templates: [],
      selectedTemplate: {},
      headerComponent: {},
      bodyComponent: {},
      footerComponent: {},
      buttonComponent: {},
      selectedVariable: {},
      customFields: {
        CUSTOM1: "",
        CUSTOM2: "",
        CUSTOM3: "",
      },
      initialValues: {
        templateName: "",
        whatsappNumber: "",
        whatsappTemplate: "",
      },
      validationSchemaObj: {
        templateName: Yup.string().required("Please enter template name"),
        whatsappNumber: Yup.string().required(
          "Please select a whatsapp number"
        ),
        whatsappTemplate: Yup.object().required("Please select a template"),
      },
    };
  }

  componentDidMount = () => {
    this.setState({
      selectedNumber: "",
      templates: [],
      selectedTemplate: {},
      headerComponent: {},
      bodyComponent: {},
      footerComponent: {},
      buttonComponent: {},
      selectedVariable: {},
    });

    this.handleGetWhatsappNumbers();
  };
  // API integration functions start from here

  // to get list of all whatsapp numbers
  handleGetWhatsappNumbers = async () => {
    try {
      const numbers = await axiosGet(`whatsapp-number`);
      if (numbers?.status) {
        this.setState({ whatsappNumbers: numbers?.data?.results });
      }
    } catch (error) {
      console.error("error at handleGetWhatsappNumbers :", error);
    }
  };

  // to get templates from meta for the particular selected whatsapp number
  // wabaId : WhatsApp Business Account ID

  handleGetTemplatesFromMeta = async wabaId => {
    try {
      const whatsappTemplates = await axiosGet(
        `whatsapp-template/available-templates?whatsappBusinessAccountId=${wabaId}`
      );
      if (whatsappTemplates?.status) {
        this.setState({ templates: whatsappTemplates?.data });
      } else {
        toast.error(whatsappTemplates?.message || "Oops! something went wrong");
      }
    } catch (error) {
      console.error("error at handleGetTemplatesFromMeta :", error);
    }
  };

  //API integration functions end here

  handleSetFieldValue = (fieldName, value, setFieldValue) => {
    setFieldValue(fieldName, value);
    this.setState(prevState => ({
      selectedVariable: {
        ...prevState.selectedVariable,
        [fieldName]: value,
      },
    }));
  };

  handleSetSelectedVariable = (variable, fieldName, setFieldValue) => {
    if (variable) {
      this.setState(
        prevState => ({
          selectedVariable: {
            ...prevState.selectedVariable,
            [fieldName]: `${
              prevState.selectedVariable[fieldName]
                ? prevState.selectedVariable[fieldName]
                : ""
            }{{${variable}}}`,
          },
        }),
        () => {
          console.log(this.state.selectedVariable);
          // Assuming setFieldValue is defined somewhere and you have access to fieldName
          if (fieldName) {
            setFieldValue(fieldName, this.state.selectedVariable[fieldName]);
          }
        }
      );
    }
  };

  handleAddTemplate = async templateData => {
    try {
      const { toggle } = this.props;
      this.setState({ showModalLoader: true });
      const { getAllTemplates } = this.props;
      const response = await axiosPost("whatsapp-template", templateData);
      if (response?.status) {
        toast.success(response?.message || "Template created successfully!");
        getAllTemplates();
        toggle();
      } else {
        toast.error("Oops! something went wrong" || response?.message);
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error("error at handleAddTemplate :", error);
    }
  };

  handleUpdateTemplate = async templateData => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllTemplates, template } = this.props;
      const response = await axiosPatch(
        `message-template/${template?.id}`,
        templateData
      );
      if (response?.status) {
        toast.success(response?.message || "Template updated successfully!");
        getAllTemplates();
        this.toggle();
      } else {
        toast.error(response?.message || "Oops! something went wrong");
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error("error at handleUpdateTemplate :", error);
    }
  };

  replacePlaceholders = (text, replacements) => {
    // Regular expression to match placeholders like {{1}}, {{2}}, etc.
    const placeholderRegex = /\{\{(\d+)\}\}/g;
    // Use replace method with a callback function to replace each placeholder
    const replacedText = text.replace(placeholderRegex, (match, index) => {
      // Extract the index from the placeholder
      const placeholderIndex = parseInt(index);

      // Check if the replacement value exists for the placeholder index
      if (replacements.hasOwnProperty(`BODY-text-${placeholderIndex}`)) {
        // Return the replacement value

        return replacements[`BODY-text-${placeholderIndex}`];
      } else {
        // Return the original placeholder if replacement value doesn't exist
        return match;
      }
    });

    return replacedText;
  };

  handleOnSubmit = async values => {
    const { bodyComponent } = this.state;
    const updatedBody = this.replacePlaceholders(bodyComponent?.text, values);

    const whatsappTemplate = JSON.parse(values?.whatsappTemplate);
    const componentData = await this.formatTemplate(values);
    console.log("componentData", componentData);
    const [wabaId, phoneNumberId] = values.whatsappNumber.split("-");
    let templateData = {};
    templateData.templateName = values?.templateName;
    templateData.templateContent = updatedBody;
    templateData.phoneNumberId = phoneNumberId;
    templateData.template = {};
    templateData.template.name = whatsappTemplate?.name;
    templateData.template.language = {};
    templateData.template.language.code = whatsappTemplate?.language;
    templateData.template.components = componentData?.components;
    console.log(templateData);
    if (templateData) {
      this.handleAddTemplate(templateData);
    }
  };

  formatTemplate = async templateData => {
    const components = [];

    // Iterate over the keys of the template data object
    console.log(templateData);
    for (const key in templateData) {
      if (templateData.hasOwnProperty(key)) {
        const [type, sub_type, index] = key.split("-"); // Split the key into type and index
        console.log("type", type);
        console.log("sub type", sub_type);
        if (
          (type === "HEADER" &&
            sub_type !== whatsappTemplateParameterTypes.LOCATION) ||
          type === "BODY"
        ) {
          // Determine the type of component
          let uploadedFilelink = "";
          if (sub_type !== whatsappTemplateParameterTypes.TEXT.toLowerCase()) {
            const response = await this.handleFileAddition();
            uploadedFilelink = response?.data?.destination; // Assuming handleFileAddition sets the uploaded file link
          }

          const componentType = type.toLowerCase();

          let existingComponentIndex = -1;
          // Check if a component of the same type already exists
          for (let i = 0; i < components.length; i++) {
            console.log("componentns[i]", components[i]);
            if (components[i] && components[i].type === componentType) {
              existingComponentIndex = i;
              break;
            }
          }

          const parameter = {};
          if (sub_type !== whatsappTemplateParameterTypes.TEXT.toLowerCase()) {
            parameter.type = sub_type.toLowerCase();
            parameter[sub_type.toLowerCase()] = { link: uploadedFilelink };
          } else {
            parameter.type = sub_type.toLowerCase();
            console.log(parameter);
            parameter[sub_type.toLowerCase()] = templateData[key];

            // New logic to add value for CUSTOM fields
            const customFields = this.state.customFields; // Access custom fields from state
            if (parameter.text === "{{CUSTOM1}}") {
              parameter.value = customFields.CUSTOM1; // Assign value from custom fields
            } else if (parameter.text === "{{CUSTOM2}}") {
              parameter.value = customFields.CUSTOM2; // Assign value from custom fields
            } else if (parameter.text === "{{CUSTOM3}}") {
              parameter.value = customFields.CUSTOM3; // Assign value from custom fields
            }
          }

          if (existingComponentIndex !== -1) {
            // If a component of the same type already exists, push the parameters to it
            components[existingComponentIndex].parameters.push(parameter);
          } else {
            // If no component of the same type exists, create a new one
            console.log(components);
            components.push({
              type: componentType,
              parameters: [parameter],
            });
          }
        } else if (type === "BUTTON") {
          // Handle button type
          const button = {
            type: type.toLowerCase(),
            sub_type: sub_type ? sub_type.toLowerCase() : undefined, // Handle undefined sub_type
            index,
            parameters: [],
          };
          if (sub_type === "COPY_CODE") {
            button.parameters.push({
              type: "coupon_code",
              coupon_code: templateData[key],
            });
          } else {
            button.parameters.push({
              type: sub_type === "URL" ? "text" : sub_type.toLowerCase(),
              text: templateData[key],
            });
          }
          components.push(button);
        }
      }
    }

    return { components };
  };

  handleNumberSelection = async (value, setFieldValue) => {
    const [wabaId, phoneNumberId] = value.split("-");

    this.setState({
      templates: [],
      selectedTemplate: {},
      headerComponent: {},
      bodyComponent: {},
      footerComponent: {},
      buttonComponent: {},
    });
    if (value) {
      this.setState({ showModalLoader: true });
      setFieldValue("whatsappNumber", value);
      this.setState({ selectedNumber: value });
      // this.setState({ templates: whatsappTemplates });
      const templates = await this.handleGetTemplatesFromMeta(wabaId);
      this.setState({ showModalLoader: false });
    } else {
      this.setState({ templates: [] });
    }
  };

  handleTemplateSelection = (value, values, setFieldValue) => {
    setFieldValue("whatsappTemplate", value); // Value is already a string
    this.setState({
      selectedTemplate: {},
      initialValues: {
        templateName: "",
        whatsappNumber: values?.whatsappNumber || "",
        whatsappTemplate: value || "",
      },
      validationSchemaObj: {
        templateName: Yup.string().required("Please enter template name"),
        whatsappNumber: Yup.string().required(
          "Please select a whatsapp number"
        ),
        whatsappTemplate: Yup.object().required("Please select a template"),
      },
    });
    const templateObject = JSON.parse(value); // Parse the value to access template properties
    this.setState({
      selectedTemplate: templateObject,
      // component: templateObject.components,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedTemplate !== prevState.selectedTemplate) {
      // Update all components when the selected template changes
      this.updateComponents();
    }
    // Check if either headerComponent or bodyComponent has changed
    if (this.state.headerComponent !== prevState.headerComponent) {
      this.updateHeaderComponent();
    }
    if (this.state.bodyComponent !== prevState.bodyComponent) {
      this.updateBodyComponent();
    }
    if (this.state.buttonComponent !== prevState.buttonComponent) {
      this.updateButtonComponent();
    }
  }

  updateComponents = () => {
    const { selectedTemplate } = this.state;
    const componentTypes = {
      [templateComponentType.HEADER]: "headerComponent",
      [templateComponentType.BODY]: "bodyComponent",
      [templateComponentType.FOOTER]: "footerComponent",
      [templateComponentType.BUTTONS]: "buttonComponent",
    };

    selectedTemplate?.components?.forEach(component => {
      const componentType = component.type;
      const stateVariable = componentTypes[componentType];
      if (stateVariable) {
        this.setState({
          [stateVariable]: component,
        });
      }
    });
  };

  updateHeaderComponent = () => {
    const { headerComponent } = this.state;

    if (
      headerComponent.format === "TEXT" &&
      Array.isArray(headerComponent.example?.header_text)
    ) {
      this.setState(prevState => {
        const initialValues = { ...prevState.initialValues };
        const validationSchemaObj = { ...prevState.validationSchemaObj };
        const sub_type = headerComponent?.format?.toLowerCase();
        headerComponent.example.header_text.forEach((text, index) => {
          const fieldName = `${headerComponent.type}-${sub_type}-${index + 1}`;
          initialValues[fieldName] = "";
          validationSchemaObj[fieldName] = Yup.string().required(
            `Please enter the value for {{${index + 1}}} for header`
          );
        });

        return {
          initialValues,
          validationSchemaObj,
        };
      });
    } else if (
      headerComponent?.format === "IMAGE" ||
      headerComponent?.format === "DOCUMENT" ||
      headerComponent?.format === "VIDEO"
    ) {
      const sub_type = headerComponent?.format?.toLowerCase();
      this.setState(prevState => ({
        initialValues: {
          ...prevState.initialValues,
          [`${headerComponent.type}-${sub_type}-uploadedFile`]: "",
        },
        validationSchemaObj: {
          ...prevState.validationSchemaObj,
          [`${headerComponent.type}-${sub_type}-uploadedFile`]:
            Yup.mixed().required("File is required"),
        },
      }));
    }
  };

  updateBodyComponent = () => {
    const { bodyComponent } = this.state;

    if (bodyComponent?.example?.body_text) {
      const bodyTexts = bodyComponent.example.body_text.flat();

      this.setState(prevState => {
        const initialValues = { ...prevState.initialValues };
        const validationSchemaObj = { ...prevState.validationSchemaObj };

        bodyTexts.forEach((text, index) => {
          const fieldName = `${bodyComponent.type}-text-${index + 1}`;
          initialValues[fieldName] = "";
          validationSchemaObj[fieldName] = Yup.string().required(
            `Please enter the value for {{${index + 1}}} for body`
          );
        });

        return {
          initialValues,
          validationSchemaObj,
        };
      });
    }
  };

  updateButtonComponent = () => {
    const { buttonComponent } = this.state;

    if (Array.isArray(buttonComponent.buttons)) {
      const buttonsWithDynamicValue = buttonComponent.buttons.filter(
        (button, index) => {
          if (button.example) {
            button.index = index;
            return true;
          }
          return false;
        }
      );
      if (buttonsWithDynamicValue.length) {
        this.setState(prevState => {
          const initialValues = { ...prevState.initialValues };
          const validationSchemaObj = { ...prevState.validationSchemaObj };

          buttonsWithDynamicValue.map((button, index) => {
            const fieldName = `BUTTON-${button.type}-${button.index}`;
            initialValues[fieldName] = "";
            validationSchemaObj[fieldName] = Yup.string().required(
              `Please enter the value for ${
                whatsappTemplateButtonTypes[button.type]
              }`
            );
          });

          return {
            initialValues,
            validationSchemaObj,
          };
        });
      }
    }
  };

  handleFileUpload = async (event, setFieldValue, fieldName, fileType) => {
    const file = event.target.files?.[0];
    const fileSize = event.currentTarget.files?.[0]?.size;
    const sizeInMb = bytesToMegabytes(fileSize);
    const sizeAllowed = fileSizeAllowed?.[fileType.toUpperCase()];

    if (sizeInMb < sizeAllowed) {
      setFieldValue(fieldName, event.target.value);
      this.setState({
        uploadedFile: file,
        imageAttachment: file,
        curMessage: file?.name,
      });
    } else {
      setFieldValue(fieldName, "");
      toast.error(
        `${capitalizeFirstLetter(
          fileType
        )} uploaded must be under ${sizeAllowed} MB in size`
      );
    }
  };

  handleFileAddition = async () => {
    try {
      const { uploadedFile } = this.state;
      // Create formData object and append imageAttachment
      // if (Object.keys(uploadedFile).length) {
      const formData = new FormData();
      formData.append("file", uploadedFile);

      // Upload image
      this.setState({ showModalLoader: true });
      const uploadResponse = await axiosPost(`upload`, formData);

      this.setState({ showModalLoader: false });
      return uploadResponse;
      // }
    } catch (error) {
      console.error("error at handleFileAddition:".error);
    }
  };

  renderHeader = (
    component,
    values,
    errors,
    touched,
    setFieldValue,
    setErrors
  ) => {
    const formattedType =
      component.type.charAt(0).toUpperCase() +
      component.type.slice(1).toLowerCase();
    if (
      component.format === "TEXT" &&
      Array.isArray(component.example?.header_text)
    ) {
      const sub_type = component?.format?.toLowerCase();
      const getAcceptedFileTypes = subType => AcceptedFileTypes[subType];
      return (
        <div className="mt-3">
          <span className="fw-bold">{formattedType}</span>
          <div className="my-2 border border-darkgrey rounded p-3">
            {component?.text ? <span className="fw-bold">Text :</span> : ""}
            <div className="border border-darkgrey rounded py-1 px-2 my-2">
              <div className="template-text my-2">
                <span>
                  {component?.format === "TEXT" ? component?.text : ""}
                </span>
              </div>
            </div>
            {component.example?.header_text?.length ? (
              <span className="fw-bold">Parameters :</span>
            ) : (
              ""
            )}
            <div className="mb-2 border border-darkgrey rounded p-3">
              {component.example?.header_text?.map((text, index) => {
                return (
                  <div className="mb-3" key={index}>
                    <div className="d-flex justify-content-between">
                      <Label className="form-label mt-2">{`{{${
                        index + 1
                      }}}`}</Label>
                      <VariableSelect
                        setSelectedVariable={value =>
                          this.handleSetSelectedVariable(
                            value,
                            `${component?.type}-${sub_type}-${index + 1}`,
                            setFieldValue
                          )
                        }
                      />
                    </div>

                    <Field
                      name={`${component?.type}-${sub_type}-${index + 1}`}
                      innerRef={this.inputRef}
                      type="text"
                      placeholder={text}
                      value={
                        values?.[`${component?.type}-${sub_type}-${index + 1}`]
                      }
                      onChange={event =>
                        this.handleSetFieldValue(
                          `${component?.type}-${sub_type}-${index + 1}`,
                          event.target.value,
                          setFieldValue
                        )
                      }
                      className={
                        "form-control" +
                        (errors[
                          `${component?.type}-${sub_type}-${index + 1}`
                        ] &&
                        touched[`${component?.type}-${sub_type}-${index + 1}`]
                          ? " is-invalid"
                          : "")
                      }
                      accept={getAcceptedFileTypes(sub_type)} // Use the constant to get accepted file types
                    />
                    <ErrorMessage
                      name={`${component?.type}-${sub_type}-${index + 1}`}
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    } else if (
      component?.format === "IMAGE" ||
      component?.format === "DOCUMENT" ||
      component?.format === "VIDEO"
    ) {
      const sub_type = component?.format?.toLowerCase();
      const sampleFile = component?.example?.header_handle[0];
      return (
        <div className="mt-3">
          <span className="fw-bold">{`${formattedType}`}</span>
          <div className="my-2 border border-darkgrey rounded p-3">
            <span className="fw-bold">{`Upload ${sub_type} :`}</span>
            <a
              href={sampleFile}
              target="_blank"
              rel="noopener noreferrer"
              className="ms-1"
            >
              View Sample
            </a>
            <div className="mb-3 mt-2">
              <Field
                type="file"
                id="file"
                accept={
                  sub_type === fileType.document
                    ? "application/pdf"
                    : sub_type === fileType.image
                    ? [".jpeg", ".png"]
                    : sub_type === fileType.video
                    ? [".mp4"]
                    : null // Add a default case or handle appropriately
                }
                name={`${component?.type}-${sub_type}-uploadedFile`}
                onChange={event => {
                  this.handleFileUpload(
                    event,
                    setFieldValue,
                    `${component?.type}-${sub_type}-uploadedFile`,
                    sub_type
                  );
                }}
                value={values[`${component?.type}-${sub_type}-uploadedFile`]}
                className={
                  "form-control" +
                  (errors[`${component?.type}-${sub_type}-uploadedFile`] &&
                  touched[`${component?.type}-${sub_type}-uploadedFile`]
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name={`${component?.type}-${sub_type}-uploadedFile`}
                component="div"
                className="text-danger"
              />
            </div>
          </div>
        </div>
      );
    }
  };

  renderBody = (component, values, errors, touched, setFieldValue) => {
    if (Array.isArray(component.example?.body_text)) {
      const formattedType =
        component.type.charAt(0).toUpperCase() +
        component.type.slice(1).toLowerCase();
      return (
        <div className="mt-3">
          <span className="fw-bold">{formattedType}</span>
          <div className="my-2 border border-darkgrey rounded p-3">
            <span className="fw-bold">Text :</span>
            <div className="border border-darkgrey rounded py-1 px-2 my-2">
              <div className="template-text my-2">
                {/* <label className="me-1">Text</label> */}
                <span>{component?.text}</span>
              </div>
            </div>
            {component.example?.body_text?.length ? (
              <span className="fw-bold">Parameters :</span>
            ) : (
              ""
            )}{" "}
            <div className="border border-darkgrey rounded pt-3 px-2 mt-2">
              {component.example.body_text.map((texts, index) =>
                texts.map((text, index2) => (
                  <div className="mb-3" key={index2}>
                    <div className="d-flex justify-content-between">
                      <Label className="form-label text-center mt-2">{`{{${
                        index2 + 1
                      }}}`}</Label>
                      <VariableSelect
                        setSelectedVariable={value =>
                          this.handleSetSelectedVariable(
                            value,
                            `${component?.type}-text-${index2 + 1}`,
                            setFieldValue
                          )
                        }
                      />
                    </div>
                    <Field
                      name={`${component?.type}-text-${index2 + 1}`}
                      type="text"
                      placeholder={text}
                      innerRef={this.inputRef}
                      value={values?.[`${component?.type}-text-${index2 + 1}`]}
                      onChange={event =>
                        this.handleSetFieldValue(
                          `${component?.type}-text-${index2 + 1}`,
                          event.target.value,
                          setFieldValue
                        )
                      }
                      className={
                        "form-control" +
                        (errors[`${component?.type}-text-${index2 + 1}`] &&
                        touched[`${component?.type}-text-${index2 + 1}`]
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name={`${component?.type}-text-${index2 + 1}`}
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  renderButtons = (component, errors, touched) => {
    if (Array.isArray(component.buttons)) {
      // const buttonsWithDynamicValue = component.buttons.filter(
      //   button => button.example
      // );

      const buttonsWithDynamicValue = component.buttons.filter(
        (button, index) => {
          if (button.example) {
            button.index = index;
            return true;
          }
          return false;
        }
      );

      if (buttonsWithDynamicValue.length) {
        const formattedType =
          component.type.charAt(0).toUpperCase() +
          component.type.slice(1).toLowerCase();
        return (
          <div className="mt-3">
            <span className="fw-bold">{formattedType}</span>
            <div className="mt-2 border border-darkgrey rounded py-2 px-3">
              {buttonsWithDynamicValue.length
                ? buttonsWithDynamicValue?.map((button, index) => (
                    <div className="mb-3" key={`${button.type}-${index}`}>
                      <Label className="form-label mt-2">
                        {whatsappTemplateButtonTypes[button.type]}
                      </Label>
                      <Field
                        name={`BUTTON-${button.type}-${button.index}`}
                        type="text"
                        placeholder={button.example}
                        className={
                          "form-control" +
                          (errors[`BUTTON-${button.type}-${button.index}`] &&
                          touched[`BUTTON-${button.type}-${button.index}`]
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name={`BUTTON-${button.type}-${button.index}`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  ))
                : ""}
            </div>
          </div>
        );
      }
    }
  };

  render() {
    const { template, showModal, toggle } = this.props;
    const {
      showModalLoader,
      selectedTemplate,
      initialValues,
      validationSchemaObj,
      whatsappNumbers,
    } = this.state;

    // const initialValues =

    let validationSchema = Yup.object().shape(validationSchemaObj);
    return (
      <Modal isOpen={showModal} className="whatsapp-template">
        {showModalLoader ? <ModalLoader /> : ""}
        <ModalHeader toggle={toggle} tag="h4">
          Add Whatsapp Template
        </ModalHeader>
        <ModalBody
          className={`pb-0
            ${
              selectedTemplate?.components?.length
                ? "whatsapp-template-body"
                : ""
            }
          `}
        >
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleOnSubmit}
          >
            {({
              errors,
              status,
              touched,
              values,
              setFieldValue,
              setErrors,
            }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Whatspp Number</Label>
                      <Field
                        as="select"
                        name="whatsappNumber"
                        className={
                          "form-control-select" +
                          (errors.whatsappNumber && touched.whatsappNumber
                            ? " is-invalid"
                            : "")
                        }
                        onChange={event => {
                          this.handleNumberSelection(
                            event.target.value,
                            setFieldValue
                          ); // Your custom function
                        }}
                        value={values.whatsappNumber}
                      >
                        <option value="">Select a number</option>
                        {whatsappNumbers?.map((number, index) => (
                          <option
                            value={`${number?.whatsappBusinessAccountId}-${number?.numberId}`}
                            key={`${number?.phoneNumber}-${index}`}
                          >
                            {number?.phoneNumber} {`  (${number.name})`}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="whatsappNumber"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Whatsapp Template</Label>
                      <Field
                        as="select"
                        name="whatsappTemplate"
                        className={
                          "form-control-select" +
                          (errors.whatsappTemplate && touched.whatsappTemplate
                            ? " is-invalid"
                            : "")
                        }
                        onChange={event =>
                          this.handleTemplateSelection(
                            event.target.value,
                            values,
                            setFieldValue
                          )
                        }
                        value={values.whatsappTemplate}
                      >
                        <option value="">Select a template</option>
                        {this.state.templates.map((template, index) => (
                          <option
                            key={`${template?.name}-${index}`}
                            value={JSON.stringify(template)}
                          >
                            {`${template?.name} - ${template?.language}`}
                          </option>
                        ))}{" "}
                      </Field>
                      <ErrorMessage
                        name="whatsappTemplate"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    {selectedTemplate?.components ? (
                      <>
                        <div>
                          <div
                            className="alert alert-warning d-flex align-items-center"
                            role="alert"
                          >
                            <i className="mdi mdi-alert font-size-24 me-3"></i>
                            <div>
                              Please keep in mind that your template should
                              closely align with the example or sample
                              text/document provided. Deviating significantly
                              may result in rejection by the meta.
                            </div>
                          </div>
                          <b className="lh-lg">
                            {selectedTemplate?.name.toUpperCase()}
                          </b>
                          <div className="mb-3 mt-2">
                            <Label className="form-label">Template Name</Label>
                            <Field
                              name="templateName"
                              type="text"
                              placeholder="Enter template name here..."
                              className={
                                "form-control" +
                                (errors.templateName && touched.templateName
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="templateName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          {selectedTemplate?.components?.map(
                            (component, index) => (
                              <div key={index}>
                                {component.type === "HEADER" &&
                                  this.renderHeader(
                                    component,
                                    values,
                                    errors,
                                    touched,
                                    setFieldValue,
                                    setErrors
                                  )}
                                {component.type === "BODY" &&
                                  this.renderBody(
                                    component,
                                    values,
                                    errors,
                                    touched,
                                    setFieldValue
                                  )}
                                {component.type === "BUTTONS" &&
                                  this.renderButtons(
                                    component,
                                    errors,
                                    touched
                                  )}
                              </div>
                            )
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="mb-3">
                      <Label className="form-label">Custom Values</Label>

                      {Object.values(this.state.selectedVariable)
                        .reduce(
                          (unique, item) =>
                            unique.includes(item) ? unique : [...unique, item],
                          []
                        )
                        .map((value, index) => {
                          if (value.includes("{{CUSTOM1}}")) {
                            return (
                              <Field
                                key={index}
                                as="input"
                                name="custom1"
                                placeholder="Enter value for Custom 1"
                                className={"form-control"}
                                onChange={e =>
                                  this.setState(prevState => ({
                                    customFields: {
                                      ...prevState.customFields,
                                      CUSTOM1: e.target.value,
                                    },
                                  }))
                                }
                              />
                            );
                          }
                          if (value.includes("{{CUSTOM2}}")) {
                            return (
                              <Field
                                key={index}
                                as="input"
                                name="custom2"
                                placeholder="Enter value for Custom 2"
                                className={"form-control"}
                                onChange={e =>
                                  this.setState(prevState => ({
                                    customFields: {
                                      ...prevState.customFields,
                                      CUSTOM2: e.target.value,
                                    },
                                  }))
                                }
                              />
                            );
                          }
                          if (value.includes("{{CUSTOM3}}")) {
                            return (
                              <Field
                                key={index}
                                as="input"
                                name="custom3"
                                placeholder="Enter value for Custom 3"
                                className={"form-control"}
                                onChange={e =>
                                  this.setState(prevState => ({
                                    customFields: {
                                      ...prevState.customFields,
                                      CUSTOM3: e.target.value,
                                    },
                                  }))
                                }
                              />
                            );
                          }
                        })}
                    </div>
                  </Col>
                </Row>
                <Row className="fixed-footer p-2">
                  {/* <div className="bg-danger"> */}
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                  {/* </div> */}
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

export default Whatsapp;
