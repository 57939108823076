import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { size } from "lodash";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Images
import logo from "../../assets/images/users/default-avatar.png";
import emptyInbox from "../../assets/images/empty-inbox.webp";

import {
  setShowNoteListModal,
  setShowChangeGroupModal,
} from "../../store/inbox/actions";
import { setShowContactFormModal } from "../../store/contacts/actions";
import NoteList from "./Modal/NoteList";
import ChangeGroup from "./Modal/ChangeGroup";
import { axiosDel, axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";
import {
  CONVERSATIONS_LIMIT,
  conversationType,
  inboxFilter,
  inboxTabTypes,
  LIMIT,
  messageTypes,
  randomString,
} from "constants/constants";
import QuickMessageList from "./Modal/QuickMessageList";
import { toast } from "react-toastify";
import DeleteModal from "components/Common/DeleteModal";
import AddNote from "./Modal/AddNote";
import ContactForm from "../Contacts/Contact Groups/Modal/ContactForm";
import {
  setLoader,
  setSelectedNotification,
  updateNotification,
} from "../../store/actions";

class InboxWhatsappMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whatsappNumbers: [],
      whatsappNumbersData: [],
      color_codes: ["#b90303", "#0377b9", "#038a20", "#00b8aa", "#ff8bdc"],
      conversations: [],
      conversation: {},
      quickMessages: [],
      showQuickMessageModal: false,
      selectFilter: {},
      selectedWhatsappNumber: "",
      deleteModal: false,
      isWhatsappMessageSending: false,
      page: 1,
      totalPages: 1,
      filterKey: "",
      isLoading: false,
      hasMore: true,
      contact: {},
      searchQuery: "",
      //
      notification_Menu: false,
      search_Menu: false,
      settings_Menu: false,
      other_Menu: false,
      Chat_Box_Username: "Steven Franklin",
      Chat_Box_User_Status: "online",
      Chat_Box_User_isActive: false,
      curMessage: "",
      imageAttachment: "",
    };
    this.messageBox = null;
    this.timer = null; // Initialize this.timer to null in the constructor
  }

  componentDidMount = async () => {
    await this.getWhatsappNumbersData();
    await this.handleGetWhatsappMessageConversations();
    await this.handleGetAllQuickMessages();
    this.handleOnNtificationClick();
  };

  componentDidUpdate(prevProps, prevState) {
    // this.state.conversations.map(con => {
    //   const found = this.state.whatsappNumbersData.find(
    //     data => data.phoneNumber === "+" + con.from
    //   );
    //   console.log(found);
    // });
    if (this.state.conversation !== prevState.conversation) {
      const { conversation } = this.state;
      const { notifications, onUpdateNotification } = this.props;
      const updatedNotificationArray = notifications.filter(notification => {
        if (
          notification.from === conversation.to &&
          notification.to === conversation.from
        ) {
          return false;
        } else {
          return true;
        }
      });
      onUpdateNotification(updatedNotificationArray);
    }

    if (
      (this.props.selectedNotification.to !==
        prevProps.selectedNotification.to ||
        this.props.selectedNotification.from !==
          prevProps.selectedNotification.from ||
        this.props.selectedNotification.index !==
          prevProps.selectedNotification.index) &&
      this.props.selectedNotification !== ""
    ) {
      this.handleOnNtificationClick();
    }

    if (this.props.latestNotification !== prevProps.latestNotification) {
      // Do something if the prop has changed
      if (this.props.latestNotification.channel === conversationType.WHATSAPP) {
        this.handleUpdateWhatsappConversations(this.props.latestNotification);
      }
    }

    // if (this.state.filterKey !== prevState.filterKey) {
    //   this.handleGetWhatsappMessageConversations(this.state.filterKey);
    // }

    if (
      prevState.conversation?.chatHistory?.length !==
      this.state.conversation?.chatHistory?.length
    ) {
      this.scrollToBottom();
    }

    // Check if conversations have changed
    const { conversations: prevConversations } = prevState;
    const { conversations: currentConversations } = this.state;

    // Check if conversations have changed
    const isConversationsChanged =
      JSON.stringify(prevConversations) !==
      JSON.stringify(currentConversations);

    // if (isConversationsChanged) {
    //   const tempConversation = [...currentConversations]; // Create a copy of the currentConversations array

    //   tempConversation.sort((a, b) => {
    //     const lastMessageA = a?.chatHistory[a.chatHistory.length - 1];
    //     const lastMessageB = b?.chatHistory[b.chatHistory.length - 1];

    //     // Compare timestamps to sort conversations by latest message
    //     return (
    //       new Date(lastMessageB?.timestamp) - new Date(lastMessageA?.timestamp)
    //     );
    //   });
    //   this.setState({
    //     conversations: tempConversation,
    //   });
    // }

    if (isConversationsChanged) {
      const tempConversation = [...currentConversations]; // Create a copy of the currentConversations array

      tempConversation.sort((a, b) => {
        const lastMessageA = a?.chatHistory?.[a?.chatHistory?.length - 1];
        const lastMessageB = b?.chatHistory?.[b?.chatHistory?.length - 1];
        const hasUnreadA = a?.unreadReceivedMessagesCount > 0;
        const hasUnreadB = b?.unreadReceivedMessagesCount > 0;

        // Prioritize unread messages
        if (hasUnreadA && !hasUnreadB) return -1;
        if (!hasUnreadA && hasUnreadB) return 1;

        // If both have the same read status, compare timestamps
        return (
          new Date(lastMessageB?.timestamp) - new Date(lastMessageA?.timestamp)
        );
      });

      this.setState({
        conversations: tempConversation,
      });
    }
  }

  handleOnNtificationClick = () => {
    const { to, from, selectedNotification, onSetSelectedNotification } =
      this.props;
    if (to && from && selectedNotification.index === "2") {
      const { conversations } = this.state;
      const toWithoutPlus = to?.replace(/\+/g, "");
      const fromWithoutPlus = from?.replace(/\+/g, "");
      const selectedConversation = conversations.filter(conversation => {
        if (
          conversation.to === fromWithoutPlus &&
          conversation.from === toWithoutPlus
        ) {
          return conversation;
        }
      });
      if (selectedConversation.length) {
        this.userChatOpen(selectedConversation[0]);
        // onSetSelectedNotification({
        //   to: selectedConversation?.[0]?.to,
        //   from: selectedConversation?.[0]?.from,
        //   index: inboxTabTypes.WHATSAPP,
        // });
      } else {
        this.setState(
          {
            page: 1,
            conversations: [],
            conversation: {},
            searchQuery: `${from?.replace(/\D/g, "")}`,
            filterKey: "",
          },
          () => {
            this.handleGetWhatsappMessageConversations(
              "",
              `${from?.replace(/\D/g, "")}`
            );
            // onSetSelectedNotification({
            //   to: to,
            //   from: from,
            //   index: inboxTabTypes.WHATSAPP,
            // });
            onSetSelectedNotification("");
          }
        );
      }
    }
  };

  getUnreadMessageCount = conversations => {
    const conversationsWithUnreadReceivedMessages = conversations.map(
      conversation => {
        // Use reduce to count the number of unread received messages for each conversation
        const unreadReceivedMessagesCount = conversation.chatHistory.reduce(
          (count, message) => {
            // Increment count if message is received and is not read
            if (message.messageType === "received" && !message.isRead) {
              return count + 1;
            }
            return count;
          },
          0
        ); // Initialize count to 0

        // Return an object with the conversation and the count of unread received messages
        return {
          ...conversation,
          unreadReceivedMessagesCount,
        };
      }
    );

    // const sortedConversations = conversationsWithUnreadReceivedMessages.sort(
    //   (a, b) => {
    //     // If a is not opened by user but b is, a should come first
    //     if (a.isOpenedByUser === false && b.isOpenedByUser === true) {
    //       return -1;
    //     }
    //     // If b is not opened by user but a is, b should come first
    //     if (b.isOpenedByUser === false && a.isOpenedByUser === true) {
    //       return 1;
    //     }
    //     // Otherwise, maintain the current order
    //     return 0;
    //   }
    // );

    return conversationsWithUnreadReceivedMessages;
  };

  markReceivedMessagesAsRead = conversation => {
    this.setState(prevState => {
      const updatedConversations = prevState.conversations.reduce(
        (acc, conv) => {
          // Check if the conversation id matches the id of the conversation to update
          if (conv._id === conversation._id) {
            // Update the conversation by merging with the new conversation
            const updatedConversation = {
              ...conv,
              chatHistory: conv.chatHistory.map(message => {
                // Check if message is received and unread
                if (message.messageType === "received" && !message.isRead) {
                  // Mark message as read
                  return {
                    ...message,
                    isRead: true,
                  };
                }
                return message;
              }),
              unreadReceivedMessagesCount: 0,
            };
            acc.push(updatedConversation); // Add the updated conversation to the accumulator
          } else {
            acc.push(conv); // Add unchanged conversations to the accumulator
          }
          return acc;
        },
        []
      );
      return { conversations: updatedConversations }; // Update the state with the new conversations array
    });
  };

  toggleQuickMessageList = () => {
    this.setState(prevState => ({
      showQuickMessageModal: !prevState.showQuickMessageModal,
    }));
  };

  toggleNote = () => {
    const { onSetShowNoteListModal } = this.props;
    onSetShowNoteListModal();
  };

  toggleEditContact = () => {
    const { onSetShowContactFormModal } = this.props;
    this.handleGetContactById();
    onSetShowContactFormModal();
  };

  handleGetContactById = async () => {
    try {
      const { conversation } = this.state;
      const response = await axiosGet(`contact/${conversation?.contactId}`);
      if (response?.status) {
        this.setState({ contact: response?.data });
      }
    } catch (error) {
      console.error("error at handleGetContactById :", error);
    }
  };

  toggleChangeGroup = () => {
    const { onSetShowChangeGroupModal } = this.props;
    onSetShowChangeGroupModal();
  };

  //Toggle Chat Box Menus
  toggleSearch = () => {
    this.setState(prevState => ({
      search_Menu: !prevState.search_Menu,
    }));
  };

  toggleSettings = () => {
    this.setState(prevState => ({
      settings_Menu: !prevState.settings_Menu,
    }));
  };

  //Use For Chat Box
  userChatOpen = conversation => {
    const { onSetSelectedNotification } = this.props;

    this.setState({ conversation }, () => {
      this.handleUpdateConversation(conversation);
      this.markReceivedMessagesAsRead(conversation);
      // onSetSelectedNotification({
      //   to: conversation.to,
      //   from: conversation.from,
      //   index: inboxTabTypes.WHATSAPP,
      // });
    });
  };

  scrollToBottom = () => {
    if (this.messageBox) {
      this.messageBox.scrollTop = this.messageBox.scrollHeight;
    }
  };

  onKeyPress = e => {
    const { key } = e;
    const { value } = e.target;

    const { imageAttachment, curMessage } = this.state;
    if (key === "Enter" && this.state.conversation) {
      if (imageAttachment) {
        this.handleUpload();
      } else {
        this.setState({ curMessage: value }, () => {
          this.handleSendWhatsappMessage();
        });
      }
    }
  };

  onClickDelete = conversation => {
    this.setState({ conversation: conversation, deleteModal: true });
  };

  //serach recent user
  // searchUsers = () => {
  //   var input, filter, ul, li, a, i, txtValue;
  //   input = document.getElementById("search-user");
  //   filter = input.value.toUpperCase();
  //   ul = document.getElementById("recent-list");
  //   li = ul.getElementsByTagName("li");
  //   for (i = 0; i < li.length; i++) {
  //     a = li[i].getElementsByTagName("a")[0];
  //     txtValue = a.textContent || a.innerText;
  //     if (txtValue.toUpperCase().indexOf(filter) > -1) {
  //       li[i].style.display = "";
  //     } else {
  //       li[i].style.display = "none";
  //     }
  //   }
  // };

  handleSelectMessage = curMessage => {
    this.setState({ curMessage }); // Set the selected message to curMessage
  };

  handleFilterSelection = event => {
    const key = event.target.value;
    if (key !== this.state.filterKey) {
      this.setState(
        {
          filterKey: key,
          conversations: [],
          conversation: {},
          page: 1,
          totalPages: 1,
        },
        () => {
          this.handleGetWhatsappMessageConversations(
            this.state.filterKey,
            this.state.searchQuery
          );
        }
      );
    }
  };

  handleWhatsappNumberChange = event => {
    const key = event.target.value;

    this.setState(
      {
        selectedWhatsappNumber: key,
        conversations: [],
        conversation: {},
        page: 1,
        totalPages: 1,
      },
      () => {
        this.handleGetWhatsappMessageConversations(
          this.state.filterKey,
          this.state.searchQuery
        );
      }
    );
  };

  handleUpdateWhatsappConversations = async latestNotification => {
    const { to, from, message, imageUrl } = latestNotification;

    const { conversations, conversation } = this.state;
    try {
      const updatedConversations = conversations.map(conversationToMatch => {
        if (
          conversationToMatch.to === from &&
          conversationToMatch.from === to
        ) {
          const objToAdd = {
            message,
            messageType: messageTypes.RECEIVED,
            isRead: false,
            isDelivered: true,
            imageUrl,
            timestamp: new Date(),
          };

          // conversation not open

          if (
            conversationToMatch.to === conversation.to &&
            conversationToMatch.from === conversation.from
          ) {
            // If the matched conversation is the same as the active conversation,
            // just add the message to its chatHistory
            const updatedConversation = {
              ...conversationToMatch,
              chatHistory: [...conversationToMatch.chatHistory, objToAdd],
            };
            this.setState({ conversation: updatedConversation });
            this.handleUpdateConversation();
            return updatedConversation;
          } else {
            // If the matched conversation is different from the active conversation,
            // increment unreadReceivedMessagesCount and add the message to its chatHistory
            return {
              ...conversationToMatch,
              chatHistory: [...conversationToMatch.chatHistory, objToAdd],
              unreadReceivedMessagesCount:
                (conversationToMatch?.unreadReceivedMessagesCount || 0) + 1,
            };
          }
        }
        return conversationToMatch;
      });

      // Update the state with the updated conversations array
      this.setState({ conversations: updatedConversations });
    } catch (error) {
      console.error("error at handleGetWhatsappMessageConversations :", error);
    }
  };

  markMessagesAsRead = (conversations, notifications) => {
    // Iterate over each conversation
    for (let i = 0; i < conversations.length; i++) {
      const conversation = conversations[i];
      const { to, from, chatHistory } = conversation;

      // Check if both 'to' and 'from' are not in notifications
      if (
        !notifications.find(
          notification => notification.to === from && notification.from === to
        )
      ) {
        // Iterate over chat history
        for (let j = 0; j < chatHistory.length; j++) {
          const message = chatHistory[j];
          // Check if message type is "received"
          if (message.messageType === "received") {
            // Mark the message as read
            message.isRead = true;
          }
        }
      }
    }
    return conversations;
  };

  // Function to debounce the search
  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ conversations: [] }, function () {
        this.handleGetWhatsappMessageConversations(
          this.state.filterKey,
          this.state.searchQuery
        );
      });
    }, WAITING_TIMING);
  };

  searchUsers = event => {
    // const { value } = event.target;
    // this.setState({ searchQuery: value }, () => {
    //   // Trigger API call to fetch conversations here
    //   this.handleGetSmsConversations(this.state.filterKey, value);
    // });
    const { value } = event.target;
    this.setState(
      {
        page: 1,
        totalPages: 1,
        // conversation: {},
        // conversations: [],
        searchQuery: value,
      },
      () => {
        this.debounceSearch(value);
      }
    );
  };

  getWhatsappNumbersData = async () => {
    const { onSetLoader } = this.props;
    try {
      // https://api.zappycom.com/api/v1/whatsapp-number?populate=assignedUserId&page=1&limit=10&sortBy=createdAt:desc
      onSetLoader(true);
      const response = await axiosGet(
        `whatsapp-number?populate=assignedUserId&page=1&limit=10000&sortBy=createdAt:desc`
      );
      if (response?.status) {
        const data = response.data.results;
        const added_color_code = data.map((wp, index) => {
          return {
            ...wp,
            color: this.state.color_codes[index],
          };
        });
        const whatsappNumbers = data.map(wp => {
          if (wp.phoneNumber && wp.name)
            return { phoneNumber: wp.phoneNumber, name: wp.name };
        });
        this.setState(prevState => ({
          ...prevState,
          whatsappNumbers: whatsappNumbers,
          whatsappNumbersData: added_color_code,
        }));

        // console.log(added_color_code);
        // this.setState(prevState => ({
        //   conversations: [...prevState.conversations, ...conversations],
        //   page: prevState.page + 1, // Increment page for pagination
        //   totalPages: response?.data?.totalPages,
        // }));
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error("error at getWhatsappNumbersData :", error);
    }
  };

  handleGetWhatsappMessageConversations = async (key = "", query = "") => {
    const { onSetLoader, notifications } = this.props;
    const filterCampaignThreads = query.length > 0 ? false : true;
    let sortBy = "&sortBy=isOpenedByUser=true";
    let groupFilter = "";
    const wpNumberFilter =
      this.state.selectedWhatsappNumber.length > 0
        ? `&from=${this.state.selectedWhatsappNumber?.slice(1)}`
        : "";
    switch (key) {
      case "isAnsweredByCustomer":
        groupFilter = `&isAnsweredByCustomer=true&isOpenedByUser=true`;
        break;
      case "isOpenedByUser":
        groupFilter = "&isOpenedByUser=false";
        break;
      default:
        break;
    }
    try {
      onSetLoader(true);
      if (this.state.page <= this.state.totalPages) {
        // const response = await axiosGet(
        //   `conversation?channel=${conversationType.WHATSAPP}&page=${
        //     this.state.page
        //   }&limit=${CONVERSATIONS_LIMIT}&sortBy=isOpenedByUser${
        //     key ? `&${key}=false` : ""
        //   }${wpFilter}&${query ? `q=${encodeURIComponent(query)}` : ""}
        //   `
        // );
        const response = await axiosGet(
          `conversation?channel=${conversationType.WHATSAPP}&page=${
            this.state.page
          }&limit=${CONVERSATIONS_LIMIT}${sortBy}${groupFilter}${wpNumberFilter}&${
            query ? `q=${encodeURIComponent(query)}` : ""
          }
          `
        );
        let conversations;
        if (response?.status) {
          conversations = this.getUnreadMessageCount(response?.data?.results);

          if (filterCampaignThreads)
            conversations = conversations.filter(campaign => {
              const lastMessage = campaign.chatHistory.slice(-1).pop();
              return !lastMessage.isTemplate;
            });

          this.setState(prevState => ({
            conversations: [...prevState.conversations, ...conversations],
            page: prevState.page + 1, // Increment page for pagination
            totalPages: response?.data?.totalPages,
          }));
        }
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error("error at handleGetWhatsappMessageConversations :", error);
    }
  };

  handleGetWhatsappConversationsAfterDelete = async () => {
    const { onSetLoader } = this.props;
    try {
      onSetLoader(true);

      const response = await axiosGet(
        `conversation?channel=${conversationType.WHATSAPP}&page=1&limit=${CONVERSATIONS_LIMIT}`
      );
      if (response?.status) {
        const conversations = this.getUnreadMessageCount(
          response?.data?.results
        );
        this.setState(prevState => ({
          conversations: [...conversations],
          page: prevState.page + 1, // Increment page for pagination
          totalPages: response?.data?.totalPages,
          conversation: "",
        }));
      }
      onSetLoader(false);
    } catch (error) {
      onSetLoader(false);
      console.error(
        "error at handleGetWhatsappConversationsAfterDelete :",
        error
      );
    }
  };

  handleGetAllQuickMessages = async () => {
    try {
      const response = await axiosGet(`quick-message?page=1&limit=${LIMIT}`);
      if (response?.status) {
        this.setState({ quickMessages: response?.data?.results });
      }
    } catch (error) {
      console.error("error at handleGetAllQuickMessages :", error);
    }
  };

  handleUpdateConversation = async () => {
    try {
      const { conversation } = this.state;
      const data = {
        isOpenedByUser: true,
      };
      // const response =
      await axiosPatch(`conversation/${conversation?._id}`, data);
      // if (!response?.status) {
      //   toast.error(response?.message || "Oops! something went wrong");
      // }
    } catch (error) {
      console.error("error at handleUpdateConversation :", error);
    }
  };

  sendBtnOnClick = event => {
    const { conversation } = this.state;
    const conversationKeyLength = Object.keys(conversation).length;
    if (conversationKeyLength > 0) {
      if (this.state.imageAttachment) {
        this.handleUpload();
      } else {
        this.handleSendWhatsappMessage();
      }
    }
  };

  handleSendWhatsappMessage = async () => {
    try {
      this.setState({ isWhatsappMessageSending: true });
      const { conversation, curMessage, conversations } = this.state;
      const response = await axiosPost(
        `conversation/send-whatsapp-message/${conversation?._id}`,
        { message: curMessage }
      );
      if (response?.status) {
        let count = 0;
        const updatedConversations = conversations.reduce((acc, conv) => {
          // If conversation with same id is found, replace it with updated data
          if (conv._id === conversation._id) {
            const updatedData = {
              ...response.data,
              _id: response.data.id,
              timestamp: new Date(),
            };
            delete updatedData.id; // Remove the 'id' field
            acc.push({ ...updatedData, timestamp: new Date() });
          } else {
            acc.push(conv);
          }
          return acc;
        }, []);

        // If conversation with same id is not found, add the updated conversation
        if (
          !updatedConversations.some(conv => {
            return (
              conv._id === conversation._id || conv.id === conversation._id
            );
          })
        ) {
          updatedConversations.push(response.data);
        }
        const updatedData = {
          ...response.data,
          _id: response.data.id,
          timestamp: new Date(),
        };
        delete updatedData.id; // Remove the 'id' field
        this.setState({
          conversation: updatedData,
          conversations: updatedConversations,
          curMessage: "",
          isWhatsappMessageSending: false,
        });
        // toast.success(response?.message || "WhatsappMessage Sended successfully!");
      } else {
        toast.error(response?.message || "Oops! something went wrong");
        this.setState({ isWhatsappMessageSending: false });
      }
    } catch (error) {
      console.error("error at handleSendWhatsappMessage :", error);
      this.setState({ isWhatsappMessageSending: false });
    }
  };

  handleFileUpload = event => {
    const file = event.target.files?.[0];
    this.setState({ imageAttachment: file, curMessage: file?.name });
  };

  handleUpload = async () => {
    try {
      this.setState({ isWhatsappMessageSending: true });
      const { imageAttachment, conversation, conversations } = this.state;

      // Check if imageAttachment is present
      if (!imageAttachment) {
        toast.error("Please select a file.");
        return;
      }

      // Create formData object and append imageAttachment
      const formData = new FormData();
      formData.append("file", imageAttachment);

      // Upload image
      const uploadResponse = await axiosPost(`upload`, formData);

      // Check if upload was successful
      if (uploadResponse?.status) {
        const imageUrl = uploadResponse?.data?.destination;

        // Send image URL in a message
        const sendImageResponse = await axiosPost(
          `conversation/send-whatsapp-message/${conversation?._id}`,
          { imageUrl }
        );

        // Check if sending message was successful
        if (sendImageResponse.status) {
          const updatedConversations = conversations.reduce((acc, conv) => {
            // If conversation with same id is found, replace it with updated data
            if (conv._id === conversation._id) {
              const updatedData = {
                ...sendImageResponse.data,
                _id: sendImageResponse.data.id,
                timestamp: new Date(),
              };
              delete updatedData.id;
              acc.push({ ...updatedData, timestamp: new Date() });
            } else {
              acc.push(conv);
            }
            return acc;
          }, []);

          // If conversation with same id is not found, add the updated conversation
          if (
            !updatedConversations.some(conv => conv._id === conversation._id)
          ) {
            updatedConversations.push(sendImageResponse.data);
          }
          // Update UI and state
          const updatedData = {
            ...sendImageResponse.data,
            _id: sendImageResponse.data.id,
            timestamp: new Date(),
          };
          delete updatedData.id; // Remove the 'id' field
          this.setState({
            imageAttachment: "",
            curMessage: "",
            conversation: updatedData,
            conversations: updatedConversations,
            isWhatsappMessageSending: false,
          });
          // toast.success(
          //   sendImageResponse?.response || "Image sent successfully!"
          // );
        } else {
          toast.error(
            sendImageResponse?.response || "Oops! something went wrong"
          );
          this.setState({ isWhatsappMessageSending: false });
        }
        // toast.success(uploadResponse?.status);
      } else {
        toast.error(uploadResponse?.status || "Oops! something went wrong");
        this.setState({ isWhatsappMessageSending: false });
      }
    } catch (error) {
      console.error("Error at handleUpload:", error);
      this.setState({ isWhatsappMessageSending: false });
    }
  };

  handleDeleteConversation = async () => {
    try {
      const { conversation } = this.state;
      const response = await axiosDel(`conversation/${conversation?._id}`);
      if (response?.status) {
        toast.success(
          response?.message || "Quick message Deleted successfully!"
        );
        await this.handleGetWhatsappConversationsAfterDelete();
        await this.handleGetAllQuickMessages();
        this.setState({ deleteModal: false });
      } else {
        toast.error(response?.message || "Oops! something went wrong");
        this.setState({ deleteModal: false });
      }
    } catch (error) {
      console.error("error at handleDeleteConversation :", error);
    }
  };

  render() {
    //meta title
    document.title = "Inbox | Whatsapp";

    const {
      conversations,
      showQuickMessageModal,
      conversation,
      curMessage,
      deleteModal,
      isWhatsappMessageSending,
      contact,
    } = this.state;

    const { showNoteListModal } = this.props;

    return (
      <React.Fragment>
        <div className="">
          <Container fluid>
            <DeleteModal
              show={deleteModal}
              onDeleteClick={this.handleDeleteConversation}
              onCloseClick={() => this.setState({ deleteModal: false })}
            />{" "}
            <AddNote conversation={conversation} />
            {showNoteListModal && <NoteList conversation={conversation} />}
            <ChangeGroup />
            <QuickMessageList
              showQuickMessageModal={showQuickMessageModal}
              onToggle={this.toggleQuickMessageList}
              handleSelectMessage={this.handleSelectMessage}
            />
            <ContactForm
              isEdit={true}
              contact={contact}
              editContactInbox={true}
            />
            <Row>
              <Col lg="12">
                <div className="d-lg-flex">
                  <div className="chat-leftsidebar me-lg-4">
                    <div className="">
                      <div className="py-4 border-bottom d-flex flex-column gap-3 ">
                        <div className="">
                          <label htmlFor="groupSelection">Select Group:</label>
                          <select
                            id="groupSelection"
                            className="form-select"
                            onChange={this.handleFilterSelection}
                            value={this.state.filterKey}
                          >
                            <option value="">All</option>
                            {Object.keys(inboxFilter).map((key, index) => (
                              <option value={key} key={`${index}-${key}`}>
                                {inboxFilter[key]}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="">
                          <label htmlFor="groupSelection">
                            Select Phone Number:
                          </label>
                          <select
                            id="groupSelection"
                            className="form-select"
                            onChange={this.handleWhatsappNumberChange}
                            value={this.state.selectedWhatsappNumber}
                          >
                            <option value={""}>All</option>
                            {this.state.whatsappNumbers.map(
                              (whatsappNumber, index) => (
                                <option
                                  value={whatsappNumber?.phoneNumber}
                                  key={`${index}-${whatsappNumber?.phoneNumber}`}
                                >
                                  {whatsappNumber?.name} - (
                                  {whatsappNumber?.phoneNumber})
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        {/* <div className="">
                          <Button
                            color="danger"
                            className="font-16 btn-block btn btn-danger"
                            // onClick={this.handleUserClicks}
                          >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            Clear Filters
                          </Button>
                        </div> */}
                      </div>

                      <div className="search-box chat-search-box mb-3">
                        <div className="position-relative">
                          <Input
                            onChange={this.searchUsers}
                            value={this.state.searchQuery}
                            id="search-user"
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                      {conversations.length > 0 ? (
                        <>
                          <div className="chat-leftsidebar-nav mt-4">
                            <div>
                              <h5 className="font-size-14 mb-3">Recent</h5>
                              <ul
                                className="list-unstyled chat-list"
                                id="recent-list"
                              >
                                <PerfectScrollbar
                                  style={{
                                    height: "410px",
                                    minHeight: "410px",
                                  }}
                                  containerRef={ref => (this.messageBox2 = ref)}
                                  onYReachEnd={() => {
                                    //   if (
                                    //     conversations.length >=
                                    //     CONVERSATIONS_LIMIT
                                    //   ) {
                                    //     this.handleGetWhatsappMessageConversations(
                                    //       this.state.filterKey
                                    //     );
                                    //   }
                                    // }
                                    this.handleGetWhatsappMessageConversations(
                                      this.state.filterKey
                                    );
                                  }}
                                >
                                  {conversations?.map((conversation, index) => (
                                    <li
                                      key={`${conversation?._id + index}`}
                                      className={
                                        this.state.conversation?._id ===
                                        conversation._id
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          this.userChatOpen(conversation);
                                        }}
                                      >
                                        <div className="d-flex">
                                          <div className="align-self-center me-3 ">
                                            <img
                                              src={logo}
                                              className="rounded-circle avatar-xs"
                                              alt=""
                                            />
                                          </div>

                                          <div className="flex-grow-1 overflow-hidden">
                                            <h5 className="text-truncate font-size-14 mb-1">
                                              {conversation?.name || "-"}
                                            </h5>
                                            <p className="text-truncate mb-0">
                                              {conversation.to}
                                            </p>
                                            <p className="text-truncate mb-0">
                                              {
                                                conversation?.latestContactGroupName
                                              }
                                            </p>
                                          </div>
                                          <div>
                                            <div className="font-size-11 left-aligned-container">
                                              {this.state.whatsappNumbersData
                                                .length > 0 && (
                                                <div className="whatsapp-name-plate">
                                                  <span
                                                    className="whatsapp-business-name-badge"
                                                    style={{
                                                      backgroundColor:
                                                        this.state.whatsappNumbersData.find(
                                                          data => {
                                                            return (
                                                              data.phoneNumber ===
                                                              "+" +
                                                                conversation.from
                                                            );
                                                          }
                                                        )?.color || "#000000",
                                                    }}
                                                  ></span>
                                                  <span className="whatsapp-name">
                                                    {this.state.whatsappNumbersData
                                                      .find(data => {
                                                        return (
                                                          data.phoneNumber ===
                                                          "+" +
                                                            conversation.from
                                                        );
                                                      })
                                                      ?.name.slice(0, 12)
                                                      .concat("...") ||
                                                      "Unknown"}
                                                  </span>
                                                </div>
                                              )}
                                              <div className="flex-col">
                                                <span
                                                  className="mdi mdi-trash-can-outline font-size-18 text-danger"
                                                  onClick={() =>
                                                    this.onClickDelete(
                                                      conversation
                                                    )
                                                  }
                                                ></span>
                                                {conversation?.unreadReceivedMessagesCount !==
                                                  0 && (
                                                  <div className="font-size-14">
                                                    <span className="badge rounded-pill bg-info">
                                                      {
                                                        conversation?.unreadReceivedMessagesCount
                                                      }
                                                    </span>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    </li>
                                  ))}
                                </PerfectScrollbar>
                              </ul>
                            </div>
                          </div>
                        </>
                      ) : (
                        <Card>
                          <div className="d-flex justify-content-center p-3">
                            <img
                              src={emptyInbox}
                              alt="image"
                              width="100%"
                            ></img>
                          </div>
                        </Card>
                      )}
                    </div>
                  </div>
                  <div className="w-100 user-chat">
                    <Card>
                      {/* {conversations.length > 0 ? ( */}
                      <>
                        <div className="p-4 border-bottom ">
                          <Row>
                            <Col
                              md="8"
                              xs="9"
                              className="d-flex justify-content-between"
                            >
                              <div className="">
                                <p>
                                  <span className="fw-bold">Name : </span>
                                  <span>{conversation?.name || "-"}</span>
                                </p>
                                <p>
                                  <span className="fw-bold">From : </span>
                                  <span>
                                    {conversation?.from
                                      ? this.state.whatsappNumbersData
                                          .find(data => {
                                            return (
                                              data.phoneNumber ===
                                              "+" + conversation.from
                                            );
                                          })
                                          ?.name.concat(
                                            `(${conversation.from})`
                                          ) || "Unknown"
                                      : "-" || "-"}
                                  </span>
                                </p>
                              </div>
                              <div className="">
                                <p>
                                  <span className="fw-bold">Address : </span>
                                  <span>{conversation?.address || "-"}</span>
                                </p>
                                <p>
                                  <span className="fw-bold">To : </span>
                                  <span>{conversation?.to || "-"}</span>
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </div>

                        <div>
                          <div className="chat-conversation p-3">
                            <ul className="list-unstyled mb-0">
                              <PerfectScrollbar
                                style={{
                                  height: "486px",
                                  minHeight: "486px",
                                }}
                                containerRef={ref => (this.messageBox = ref)}
                              >
                                {conversation &&
                                  conversation?.chatHistory?.map(
                                    (message, index) => {
                                      const timestamp = message.timestamp; // Assuming createdAt is the timestamp of the message
                                      const isFirstMessageOfDay =
                                        index === 0 ||
                                        moment(timestamp)
                                          .startOf("day")
                                          .valueOf() >
                                          moment(
                                            conversation?.chatHistory?.[
                                              index - 1
                                            ]?.timestamp
                                          )
                                            .startOf("day")
                                            .valueOf();

                                      return (
                                        <React.Fragment
                                          key={`${message.timestamp}-${index}`}
                                        >
                                          {isFirstMessageOfDay && (
                                            <li key={randomString(10, 3)}>
                                              <div className="chat-day-title">
                                                <span className="title">
                                                  {moment(timestamp).format(
                                                    "MMM-DD-YYYY"
                                                  )}
                                                </span>
                                              </div>
                                            </li>
                                          )}

                                          <li
                                            className={
                                              message.messageType ===
                                              messageTypes.SEND
                                                ? "right"
                                                : ""
                                            }
                                          >
                                            <div
                                              className={`conversation-list ${
                                                message.messageType ===
                                                messageTypes.SEND
                                                  ? "me-4"
                                                  : ""
                                              }`}
                                            >
                                              <div
                                                className={`conversation-name ${
                                                  message.messageType ===
                                                  messageTypes.SEND
                                                    ? "d-flex justify-content-end"
                                                    : ""
                                                }`}
                                              ></div>
                                              <div className="ctext-wrap">
                                                {message?.imageUrl ? (
                                                  <a
                                                    href={message?.imageUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    <img
                                                      src={message?.imageUrl}
                                                      alt="image sent"
                                                      width={150}
                                                    />
                                                  </a>
                                                ) : message.message ? (
                                                  <p className="m-0">
                                                    {message.message}
                                                  </p>
                                                ) : (
                                                  <p className="m-0 px-3 bg-info text-dark rounded">
                                                    <span>
                                                      This file format is not
                                                      supported!
                                                    </span>
                                                    <i className="ms-2 font-size-16 mdi mdi-image-off"></i>
                                                  </p>
                                                )}
                                              </div>
                                              <p
                                                className={`chat-time mb-0 ${
                                                  message.messageType ===
                                                  messageTypes.SEND
                                                    ? "d-flex justify-content-end"
                                                    : ""
                                                }`}
                                              >
                                                {moment(
                                                  message.timestamp
                                                ).format("h:mm A")}
                                              </p>
                                            </div>
                                          </li>
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                              </PerfectScrollbar>
                            </ul>
                          </div>
                          <div className="p-3 chat-input-section">
                            <Row>
                              <Col>
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    value={curMessage}
                                    onKeyPress={this.onKeyPress}
                                    onChange={e => {
                                      this.setState({
                                        curMessage: e.target.value,
                                      });
                                    }}
                                    className="form-control chat-input"
                                    placeholder="Enter Message..."
                                    disabled={
                                      Object.keys(conversation).length <= 0
                                    }
                                  />
                                  <div className="chat-input-links">
                                    <ul className="list-inline mb-0">
                                      <li className="list-inline-item">
                                        <label
                                          htmlFor="fileInput"
                                          className="pt-2"
                                        >
                                          <i
                                            className="mdi mdi-link-variant font-size-18 "
                                            id="Imagetooltip"
                                          ></i>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="Imagetooltip"
                                          >
                                            Images
                                          </UncontrolledTooltip>
                                        </label>
                                        <input
                                          type="file"
                                          id="fileInput"
                                          className="display-none"
                                          accept="image/*"
                                          onChange={this.handleFileUpload}
                                          disabled={
                                            Object.keys(conversation).length <=
                                            0
                                          }
                                        />
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </Col>
                              <Col className="col-auto">
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={this.sendBtnOnClick}
                                  className="btn-rounded chat-send w-md me-2"
                                  disabled={
                                    Object.keys(conversation).length <= 0
                                  }
                                >
                                  <span className="d-none d-sm-inline-block me-2">
                                    {isWhatsappMessageSending
                                      ? "Sending..."
                                      : "Send"}
                                  </span>{" "}
                                  <i className="mdi mdi-send"></i>
                                </Button>
                                <Button
                                  type="button"
                                  color="primary"
                                  className="btn-rounded chat-send me-2"
                                  onClick={() => this.toggleQuickMessageList()}
                                  disabled={
                                    Object.keys(conversation).length <= 0
                                  }
                                >
                                  <i className="mdi mdi-chat-outline"></i>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </>
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

InboxWhatsappMessage.propTypes = {
  onSetShowNoteListModal: PropTypes.func,
  onSetShowChangeGroupModal: PropTypes.func,
  onSetShowContactFormModal: PropTypes.func,
  onSetLoader: PropTypes.func,
};

const mapStateToProps = ({ contacts, inbox, notification }) => ({
  showNoteListModal: inbox.showNoteListModal,
  showChangeGroupModal: inbox.showChangeGroupModal,
  showContactFormModal: contacts.showContactFormModal,
  latestNotification: notification.latestNotification,
  selectedNotification: notification.selectedNotification,
  notifications: notification.notifications,
});

const mapDispatchToProps = dispatch => ({
  onSetShowNoteListModal: () => dispatch(setShowNoteListModal()),
  onSetShowChangeGroupModal: () => dispatch(setShowChangeGroupModal()),
  onSetShowContactFormModal: () => dispatch(setShowContactFormModal()),
  onSetLoader: bool => dispatch(setLoader(bool)),
  onSetSelectedNotification: data => dispatch(setSelectedNotification(data)),
  onUpdateNotification: data => dispatch(updateNotification(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InboxWhatsappMessage);
